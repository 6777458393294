import { render, staticRenderFns } from "./ToolbarNotifications.vue?vue&type=template&id=1323743c"
import script from "./ToolbarNotifications.vue?vue&type=script&lang=js"
export * from "./ToolbarNotifications.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-385497401/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports