import { render, staticRenderFns } from "./AuthLayout.vue?vue&type=template&id=66bfc24c&scoped=true"
import script from "./AuthLayout.vue?vue&type=script&lang=js"
export * from "./AuthLayout.vue?vue&type=script&lang=js"
import style0 from "./AuthLayout.vue?vue&type=style&index=0&id=66bfc24c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-385497401/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66bfc24c",
  null
  
)

export default component.exports